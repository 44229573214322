import React, { useState, useEffect } from "react";
import { getGalleries } from "../api/Api";
import { useGalleryUpdate, useGallery } from "../hooks/GalleryContext";
import  Spinner  from "./partials/Spinner";

const Gallery = (props) => {
  const [fullImage, setFullImage] = useState("");
  const [galleries, setGalleries] = useState([]);
  const [categories, setCategories] = useState([]);

  const closeGallery = useGalleryUpdate();
  const gallery = useGallery();

  useEffect(async() => {
    const data = await getGalleries()
    
    setGalleries(data);

    let cat = data.data.map(gal => gal.category);
    console.log(cat);
    setCategories(cat);
  }, []);

  const changeCategory = (e) => {
    const activeTab = document.querySelector('.tab-panel.active');
    const activeNav = document.querySelector('.tab-nav .active');

    if(activeTab) activeTab.classList.remove('active');
    if(activeNav) activeNav.classList.remove('active');


    document.querySelector(`#tab_${e.target.closest('li').getAttribute('data-panel')}`)
     .classList.add('active');

    e.target.closest('li').classList.add('active');
  }

  return (
    <section className="gallery wrap" id="gallery">  
        <div className="modal">
          <div className="modal--close" onClick={(e) => {document.body.classList.toggle("modal--open"); closeGallery(false, 0);}}>
            x
          </div>
          {galleries.data ? 
            (
              <div className="tab-container">
                <div className="tab-nav">
                  <ul>
                    {
                      categories && categories.map((cat, index) => {
                      return <li key={index} data-panel={index} onClick={(e)=>changeCategory(e)} className={cat._id == gallery.category ? 'active' : ''}>
                          <div className="option-icon">
                            {/* <img src={cat.icon.url} alt="" />  */}
                          </div>
                          <label>{ cat.title }</label>
                        </li>
                      })
                    }
                  </ul>
                </div>
                <div className="tab-panel__container">
                  {
                    galleries.data.map((galleries, index) => {
                      return <div key={index} className={galleries.category.id == gallery.category ? 'tab-panel active' : 'tab-panel'} id={`tab_${index}`}>
                        <div className="img-grid">
                          {
                            galleries.images && galleries.images.map((img) => (
                              <div className="img-wrap" key={img.id}>
                                <img
                                  src={`${img.formats.thumbnail.url}`}
                                  onClick={()=>setFullImage(img)}
                                />
                              </div>
                            ))
                          }
                        </div>
                      </div>
                    }
                    )
                  }
                </div>
            </div>

            )
            : <Spinner />
          }
          
          { fullImage ? 
          <div className="full-img">
            <div className="full-img--title">{ fullImage.caption }</div>
            <div className="img-wrap--full">
            <div className="full-img--close" onClick={() => setFullImage(null)}>
              x
            </div>
            <img src={`${fullImage.url}`} />
            </div>
          </div>
          : '' }
        </div> 
    </section>
  );
};

export default Gallery;
