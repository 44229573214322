import React from "react"

import ServiceCard from "./partials/ServiceCard";

const Services = props => {
  const { title, service } = props.data;

  return (
    <section className="services wrap bg-gray" id="services">
      <h2 className="title">{ title }</h2>
      <div className="services-container">
        {
          service.map(s => {
            return <ServiceCard service={s} key={s.id} />
          })
        }
      </div>
    </section>
  )
}

export default Services