import React from "react"
import { useGalleryUpdate } from "../../hooks/GalleryContext";

import { ButtonService } from "./Button"

const ServiceCard = props => {
  const { service } = props.service;

  const showGallery = useGalleryUpdate();

  return (
    <div className="card-wrap" >
      <div className="card-imgBx" data-text={service.title} style={ {backgroundImage: `url(${service.bgImage.url})`} }>
        <img src={`${service.icon.url}`} alt="" />
      </div>
      <div className="card-content">
        <h2>{service.title}</h2>
        <p>{service.description}</p>
         { service.gallery ? <button className="btn-service" onClick={ ()=> {document.body.classList.toggle("modal--open");showGallery(true, service._id);} }>ukázka prací</button> : "" }
      </div>
    </div>
  )
}

export default ServiceCard