import React from "react"

const Footer = () => {
  return (
    <div className="footer">
      © 2020 Všechna práva vyhrazena | created by Vlastimil Dostál
    </div>
  )
}

export default Footer