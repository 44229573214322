import React, {useState, useEffect} from "react"

const Introduction = props => {

  const {title, subtitle} = props.data;

  return (
    <section className="introduction">
      <div className="bg-image" />
      <div className="wrap introduction-content">
        <h1>{ title }</h1>
        <h2>{ subtitle }</h2>
      </div>
    </section>
  )
}

export default Introduction