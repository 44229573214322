import React from "react"

export const ButtonPrimary = props => {

  const {type, text} = props;
  return <button className="btn btn-primary" type={type}>{text}</button>
}

export const ButtonDown = props => {
  return (
    <div class="btn-down">
      <svg
        class="icon"
        style="width: 1em; height: 1em;vertical-align: middle;fill: currentColor;overflow: hidden;"
        viewBox="0 0 1024 1024"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg">
        <path d="M510.976 745.36L250.288 484.672l-22.624 24.992 272 274.336h22.624l272-274.336-22.624-23.808z" fill="#fff" />
        <path d="M510.976 505.36L250.288 244.672l-22.624 24.992 272 274.336h22.624l272-274.336-22.624-23.808z" fill="#fff" />
      </svg>
    </div>
  )
}

export const ButtonService = props => {
  const handleClick = () => {
   // setCategory(props.link)
  }
  return <div href={props.link} className="btn-service" onClick={handleClick}>{props.text}</div>
}