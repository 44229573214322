import axios from "axios"
import { path } from "path";


const req = axios.create({
  baseURL: "/",
  timeout: 4000,
  json: true,
  headers: {
      "content-type": "application/json",
  }
})

export async function getContent () {
  return await req.get("homepage");
}

export async function getGalleries() {
  return await req.get("galleries");
}

export function sendMail(data) {
  const body = `
    <p>Jméno: ${data.name}</p>
    <p>E-mail: ${data.mail}</p>
    <p>Zpráva: ${data.msg}</p>
  `
  
  const mail = {
    to: data.recipient,
    replyTo: data.mail,
    from: "postmaster@stavby-kolar.eu",
    subject: data.subject,
    html: body
  }

  return req.post("email", JSON.stringify(mail))

}