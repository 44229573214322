import React from "react"

import { sendMail } from "../../api/Api";
import {ButtonPrimary} from "./Button"

const ContactForm = props => {

  const handleSubmit = (e) => {
    e.preventDefault()
    const form = e.target
    const formData = new FormData(form)
    const data = Array.from(formData.entries()).reduce((memo, pair) => ({
      ...memo,
      [pair[0]]: pair[1],
    }), {});

    data.recipient = props.recipient;

    sendMail(data)
      .then(()=> {
        alert('zpráva byla úspěšně odeslána');
        form.reset();
      })
  }

  return (
    <form className="contact-form" onSubmit={handleSubmit} >
        <label htmlFor="name" >Jméno:</label>
        <input type="text" id="name" name="name" required/>
        <label htmlFor="mail" >Email:</label>
        <input type="text" id="mail" name="mail" required/>
        <label htmlFor="subject" >Předmět:</label>
        <input type="text" id="subject" name="subject" required/>
        <label htmlFor="text" >Zpráva:</label>
        <textarea id="text" name="msg"  required/>
        {/* <input type="file" name="attachment"/> */}
        <ButtonPrimary text="Odeslat" type="submit"/>
      </form>
  )
}

export default ContactForm