import React, { useState, useEffect } from "react"
import Navigation from './components/Navigation';
import Introduction from './components/Introduction';
import Service from './components/Services';
import AboutMe from './components/AboutMe';
import Gallery from './components/Gallery';
import {getContent} from './api/Api';
import { useGallery, useGalleryUpdate } from "./hooks/GalleryContext";
import Spinner from "./components/partials/Spinner";
import Slider from "./components/Slider";
import Contact from "./components/Contact";
import Footer from "./components/Footer";


function App() {
  const [data, setData] = useState(undefined);

  const showGallery = useGallery();

  useEffect(() => {
   getContent()
      .then(res => {
        setData(res.data);
      })
  }, []);

  return (
    <div className="App">
      {!data ? 
        <Spinner />
        :
          <>
            <main>
              <Introduction data = {data.header} />
              <Navigation />
              <Service data={data.services} />
              <AboutMe title={data.aboutme.title} text={data.aboutme.content}/>
              <Slider images={data.Slider.slides} title={data.Slider.title} services={data.services}/>
              { showGallery && showGallery.show ? <Gallery/> : ""}
              <section className="only-mobile"><h2 className="title only-mobile" >{data.contact.title}</h2></section>
              <Contact data={data.contact}/>
              <Footer />
            </main>
          </>
      }
    </div>
  );
}

export default App;
