import React, { useState } from "react"

import { Link, animateScroll } from "react-scroll"


const Navigation = props => {

  // const [isTop, setIsTop] = useState(true);

  // document.addEventListener("scroll", () => {
  //   (window.pageYOffset !== 0) ? setIsTop(false) : setIsTop(true)
  // });

  return (
    <>
      <nav className="navbar">
        <div className="wrap links">
          <Link
            to="services"
            spy={true}
            smooth={true}
            duration={1000}
            isDynamic={true}
            ignoreCancelEvents={false}
            offset={-70}
          > Služby</Link>

          <Link
            to="aboutMe"
            spy={true}
            smooth={true}
            duration={1000}
            isDynamic={true}
            ignoreCancelEvents={false}
            offset={-70}
          > O mně</Link>

          <Link
            to="gallery"
            spy={true}
            smooth={true}
            duration={1000}
            isDynamic={true}
            ignoreCancelEvents={false}
            offset={-70}
          > Galerie</Link>

          <Link
            to="contact"
            spy={true}
            smooth={true}
            duration={1000}
            isDynamic={true}
            ignoreCancelEvents={false}
            offset={-70}
          > Kontakt</Link>
        </div>
      </nav>
    </>
  )
}

export default Navigation