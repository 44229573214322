import React from "react"

import ContactForm from "./partials/ContactForm";
import smartphone from "../images/smartphone.svg";
import mail from "../images/mail.svg";


const Contact = props => {
  const { title, contacttype } = props.data;

  return (
    <section className="contact wrap" id="contact"> 
        <div className="contact-container wrap">
          <div className="contact-formBx">
          <div className="overlayer" />
            <ContactForm recipient={contacttype[2].value}/>
          </div>
          <div className="contact-box">
          <h2 className="title desktop" >{title}</h2>
            <ul>
              <li>
                <div className="contact-icon"><span>IČO:</span></div>
                <span>{contacttype[0].value}</span>
              </li>
              <li>
                <div className="contact-icon">
                  <img src={smartphone} />
                </div>
                <span>{contacttype[1].value} </span>
              </li>
              <li>
                <div className="contact-icon">
                <img src={mail} />
              </div>
                <span>{contacttype[2].value}</span></li>
            </ul>
          </div>
        </div>
    </section>
  )
}

export default Contact