import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { useGallery, useGalleryUpdate } from "../hooks/GalleryContext";

const Slider = (props) => {
  const { images, title, services } = props;

  const setShowGallery = useGalleryUpdate();
  const showGallery = useGallery();

  const countSlide = window.innerWidth < 768 ? '100' : '33';

  const handleShowGallery = () => {
    document.body.classList.add("modal--open");

    let categoryId = services.service.filter((s) => s.service.gallery);
    categoryId = categoryId[0].service.id;

    setShowGallery(!showGallery.show, categoryId);
  };

  return (
    <section className="slider wrap bg-gray" id="gallery">
      <div className="row">
        <h2 className="title">{title}</h2>
        <Carousel
          centerMode="true"
          centerSlidePercentage={countSlide}
          thumbWidth={220}
          showThumbs={false}
          autoPlay={true}
          infiniteLoop={true}
        >
          {images.map((img, index) => {
            return (
              <div key={index}>
                <img src={`${img.url}`} />
              </div>
            );
          })}
        </Carousel>
        <button
          className="btn btn-primary m-auto"
          onClick={() => handleShowGallery()}
        >
          Zobrazit galerii
        </button>
      </div>
    </section>
  );
};

export default Slider;
