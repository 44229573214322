import React from "react"

const AboutMe = props => {
  return (
    <section className="about-me" id="aboutMe">
      <div className="bg-parallax" />
      <div className="about-me-text">
      <h2 className="title">{props.title}</h2>
      <p>{ props.text }</p>
      </div>
    </section>
  )
}

export default AboutMe