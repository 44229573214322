import React, { useContext, useState } from "react"

const GalleryContext = React.createContext()
const GalleryUpdateContext = React.createContext()

export function useGallery() {
  return useContext(GalleryContext)
}
export function useGalleryUpdate() {
  return useContext(GalleryUpdateContext)
}

export function GalleryProvider({ children }) {
  const [gallery, setGallery] = useState({show: false, category: 0})

  const updateGallery = (show, category) => {
    setGallery({show: show, category: category});

    const navbar = document.querySelector('.navbar');

    show ? navbar.style.display = 'none' : navbar.style.display = 'flex'
  }
  
  return (
    <GalleryContext.Provider value={ gallery }>
      <GalleryUpdateContext.Provider value={ updateGallery } >
        {children}
      </GalleryUpdateContext.Provider>
    </GalleryContext.Provider>
  )
}